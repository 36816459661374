// ** Import from react dom
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';

// ** Import Layouts
import DashboardIndex from 'layouts/dashboard/DashboardIndex';
import AuthLayout from 'layouts/dashboard/AuthLayout';

// ** Import Dahbaord Menu Pages
import Overview from 'components/dashboard/overview/Overview';
import Analytics from 'components/dashboard/analytics/Analytics';

// ** Import Courses Pages
import AllCourses from 'components/dashboard/courses/all-courses/AllCourses';
import EnrollEmployee from 'components/dashboard/business/user/EnrollEmployees';
import BusinessCourses from 'components/dashboard/business/all-courses/BusinessCourses';
import BusinessPayment from 'components/dashboard/business/payment/AllPayments';
import BusinessEmployee from 'components/dashboard/business/user/Students';
import BusinessAddEmployee from 'components/dashboard/business/user/AddNewEmployee';
import CoursesCategory from 'components/dashboard/courses/CoursesCategory';
import BusinessSubscription from 'components/dashboard/business/subscription/BusinessSubscriptions';
import BusinessAnalytics from 'components/dashboard/business/analytics/Analytics';
import Reviews from 'components/dashboard/review/AllReviews';
import Cohorts from 'components/dashboard/cohort/AllCohorts';
import MailDetails from 'components/dashboard/mail-app/mail-details/MailDetails';
import Mail from 'components/dashboard/mail-app/mail/Mail';
import MailBox from 'components/dashboard/mail-app/mail/MailBox';
import MailSent from 'components/dashboard/mail-app/mail/MailSent';

import Discussion from 'components/dashboard/chat/Chat';
import ChatLayout from './dashboard/ChatLayout';

// ** Import Users Pages
import Students from 'components/dashboard/user/Students';

// ** Import Authentication components
import SignIn from 'components/dashboard/authentication/SignIn';
import SignUp from 'components/dashboard/authentication/SignUp';
import Notifications from 'components/dashboard/authentication/Notifications';

// ** Import Settings components
import General from 'components/dashboard/settings/General';
import Google from 'components/dashboard/settings/Google';
import Social from 'components/dashboard/settings/Social';
import SocialLogin from 'components/dashboard/settings/SocialLogin';
import Payment from 'components/dashboard/settings/Payment';
import SMTPServer from 'components/dashboard/settings/SMTPServer';
import CohortSetting from 'components/dashboard/settings/Cohort';

import FAQ from 'components/dashboard/settings/FAQ';
import Site from 'components/dashboard/settings/Site';
import SiteTeamMembers from 'components/dashboard/settings/SiteTeamMembers';

import StudentQuiz from 'components/dashboard/student/quiz/Quiz';
import StudentQuizStart from 'components/dashboard/student/quiz/quiz-start/QuizStart';
import StudentQuizAttempt from 'components/dashboard/student/quiz/QuizAttempt';
import StudentQuizResult from 'components/dashboard/student/quiz/QuizResult';

import MyCourses from 'components/dashboard/student/course/Courses';
import MySubscription from 'components/dashboard/student/subscription/MySubcription';
import MyPayments from 'components/dashboard/student/payment/MyPayments';
import MailLayout from './dashboard/MailLayout';

// Dashboard Projects ( new v1.2.0 )

import NotFound from 'layouts/marketing/NotFound';

/* IMPORTS FOR FRONT SIDE PAGES MENU */
import AddNewCourse from 'components/dashboard/courses/add-new-course/AddNewCourse';
import UpdateExistingCourse from 'components/dashboard/courses/update-existing-course/UpdateExistingCourse';

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import Error404 from 'components/Error404';

import ProtectedRoute from './ProtectedRoute';
import AddNewQuiz from 'components/dashboard/courses/quiz/AddNewQuiz';
import EditQuiz from 'components/dashboard/courses/update-quiz/EditQuiz';
import AllQuizes from 'components/dashboard/courses/quiz/AllQuizes';

// import AddNewTask from 'components/dashboard/courses/add-task/AddNewTask';
//import EditTask from 'components/dashboard/courses/update-task/EditTask';
// import AllTasks from 'components/dashboard/courses/add-task/AllTasks';
// import WorkSpace from 'components/dashboard/courses/add-task/WorkSpace';

// import AddNewAssessment from 'components/dashboard/courses/assessment/AddNewAssessment';
// import AllAssessment from 'components/dashboard/courses/assessment/AllAssessment';

import AllCurrency from 'components/dashboard/currency/AllCurrency';
import AllPlans from 'components/dashboard/plan/AllPlans';
import PlanForm from 'components/dashboard/plan/PlanForm';
import AllPayment from 'components/dashboard/payment/AllPayments';
import VerifyAccount from 'components/dashboard/authentication/VerifyAccount';

import { useSelector } from 'react-redux';
import CourseLearning from 'components/dashboard/student/course/CourseLearning';
import Thread from 'components/dashboard/chat/chatbox/Thread';
import Message from 'components/dashboard/chat/chatbox/Message';
import DiscussionList from 'components/dashboard/chat/discussion/DiscussionList';
import CourseList from 'components/dashboard/trainer/course/CourseList';
import QuizList from 'components/dashboard/trainer/quiz/QuizList';
import PayoutList from 'components/dashboard/trainer/payout/PayoutList';
import EarningCard from 'components/dashboard/trainer/earning/EarningCard';
// import EditAssessment from 'components/dashboard/courses/update-assessment/EditAssessment';

const AllRoutes = () => {
	const {user} = useSelector((state) => state.auth);
	return (
			<Routes>
				{/* Routes with DefaultLayout */}

				{/* Routes (DASHBOARD ROUTERS) with DashboardIndex */}
				<Route element={<DashboardIndex />}>
					<Route element={
						<ProtectedRoute 
							isLogin={Boolean(user && user?.isVerified !== null)} 
							redirectPath={user && user?.isVerified == null ? "/verify/account": ""} 
						/>}>
						<Route path="/dashboard/overview" element={<Overview />} />
						<Route path="/dashboard/analytics" element={<Analytics />} />
						<Route path="/dashboard/courses/all-courses" element={<AllCourses />} />
						<Route path="/dashboard/business/enroll" element={<EnrollEmployee />} /> 
						<Route path="/dashboard/business/courses" element={<BusinessCourses />} /> 
						<Route path="/dashboard/business/payments" element={<BusinessPayment />} /> 
						<Route path="/dashboard/business/employees" element={<BusinessEmployee />} />
						<Route path="/dashboard/business/create/user" element={<BusinessAddEmployee />} /> 
						<Route path="/dashboard/business/subscriptions" element={<BusinessSubscription />} />
						<Route path="/dashboard/business/analytics" element={<BusinessAnalytics />} />
						<Route path="/dashboard/courses/courses-category" element={<CoursesCategory />} />
						<Route path="/dashboard/course/add-quiz" element={<AddNewQuiz />} />
						<Route path="/dashboard/reviews" element={<Reviews />} />
						<Route path="/dashboard/course/edit-quiz/:id" element={<EditQuiz />} />
						{/* <Route path="/dashboard/course/edit-assessment/:id" element={<EditAssessment />} /> */}
						<Route path="/dashboard/course/quizes" element={<AllQuizes />} />
						<Route path="/dashboard/cohorts" element={<Cohorts />} />
						{/* <Route path="/dashboard/course/add-task" element={<AddNewTask />} />
						<Route path="/dashboard/course/tasks" element={<AllTasks />} />
						<Route path="/dashboard/course/task/workspace" element={<WorkSpace />} /> */}

						{/* <Route path="/dashboard/course/add-assessment" element={<AddNewAssessment />} />
						<Route path="/dashboard/course/assessments" element={<AllAssessment />} /> */}

						<Route path="/student/start/learning/:slug" element={<CourseLearning />} />
						<Route path="/student/quiz/:quizId" element={<StudentQuiz />} />
						<Route path="/student/quiz/attempt/" element={<StudentQuizAttempt />} />
						<Route path="/student/quiz/start/" element={<StudentQuizStart />} />
						<Route path="/student/quiz/result/" element={<StudentQuizResult />} />

						<Route path="/dashboard/student/courses" element={<MyCourses />} />
						<Route path="/dashboard/student/subscriptions" element={<MySubscription />} />
						<Route path="/dashboard/student/payments" element={<MyPayments />} />

						<Route path="/dashboard/currencies" element={<AllCurrency />} />

						<Route path="/dashboard/plans" element={<AllPlans />} />
						<Route path="/dashboard/plan/create" element={<PlanForm />} />
						<Route path="/dashboard/discussion-thread" element={<DiscussionList />} />

						<Route path="/dashboard/payments" element={<AllPayment />} />

						<Route path="/dashboard/course/add-new" element={<AddNewCourse />} /> 
						<Route path="/dashboard/course/update/:slug" element={<UpdateExistingCourse />} />
						<Route path="/dashboard/user/students" element={<Students />} />
					
						<Route
							path="/authentication/notifications"
							element={<Notifications />}
						/>
				

						{/* SETTINGS ROUTERS */}
						<Route path="/dashboard/settings/general" element={<General />} />
						<Route path="/dashboard/settings/google" element={<Google />} />
						<Route path="/dashboard/settings/cohort" element={<CohortSetting />} />
						<Route path="/dashboard/settings/social" element={<Social />} />
						<Route path="/dashboard/settings/social-login" element={<SocialLogin />} />
						<Route path="/dashboard/settings/payment" element={<Payment />} />
						<Route path="/dashboard/settings/smtp-server" element={<SMTPServer />} />
						<Route path="/dashboard/settings/site/team" element={<SiteTeamMembers />} />
						<Route path="/dashboard/settings/site/faq" element={<FAQ />} />
						<Route path="/dashboard/settings/sites" element={<Site />} />

						{/***** Trainer Routes ******/}
						<Route path="/dashboard/trainer/courses" element={<CourseList />} />
						<Route path="/dashboard/trainer/quizzes" element={<QuizList />} />
						<Route path="/dashboard/trainer/payouts" element={<PayoutList />} />
						<Route path="/dashboard/trainer/earning" element={<EarningCard />} />
						
		
					</Route>
				</Route>

				<Route element={<MailLayout />}>
					<Route path="/dashboard/mail" element={<Mail />} >
						<Route path="inbox" element={<MailBox />} />
						<Route path="sent" element={<MailSent />} />
						<Route path=":type/message/:id" element={<MailDetails />} />
					</Route>
				</Route>

				{/* Routes with ChatLayout */}
				{/* <Route element={<ChatLayout />}>
					<Route path="/dashboard/discussion" element={<Discussion />}>
						<Route path=":id" element={<Thread />} />
						<Route path=":messageId" element={<Message />} />
					</Route>
				</Route> */}

				<Route element={<ChatLayout />}>
					<Route path="/dashboard/discussion" element={<Discussion />}>
						{/* Define nested routes inside Discussion */}
						<Route path="group/:groupId" element={<Thread />} />
						<Route path="group/:groupId/thread/:threadId" element={<Message />} />
					</Route>
				</Route>

				{/* Routes with AuthLayout */}
				<Route element={<AuthLayout />}>
					<Route path="/" element={<SignIn />} />
					<Route path="/auth/register" element={<SignUp />} />
					<Route path="/verify/account" element={<VerifyAccount />} />
				</Route> 

				{/* Routes with NotFound */}
				<Route element={<NotFound />}>
					<Route path="/specialty/404-error/" element={<Error404 />} />
				</Route>

				{/*Redirect*/}
				<Route
					path="*"
					element={<Navigate to="/specialty/404-error/" replace />}
				/>
			</Routes>
	);
};

export default AllRoutes;

import React from 'react';
import TableWrapper from '../../common/table/TableWrapper';
import QuizTable from './QuizTable'; // Your existing courseTable component
import { useFetchData } from 'hooks/useFetchData';

const QuizList = () => {
  const { data } = useFetchData('/trainer/quizzes')
  const tabs = [
    { eventKey: 'all', label: 'All', component: <QuizTable courses={data?.data?.data || []} /> },
  ];

  return (
    <TableWrapper
      title="All Quizzes"
      breadcrumbs={[
        { label: 'Home', link: '#' },
        { label: 'All', active: true },
      ]}
      buttonLink="#"
      buttonText="Add New Quizzes"
      tabs={tabs}
      defaultTabKey="all"
    />
  );
};

export default QuizList;

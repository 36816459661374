import React from 'react';
import { Table, Button, Dropdown } from 'react-bootstrap';
import { MoreVertical } from 'react-feather';

// Custom Dropdown Action Menu Component
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant="link"
    className="btn-icon btn-ghost btn-sm rounded-circle"
  >
    {children}
  </Button>
));

const ActionMenu = ({ actions }) => (
  <Dropdown>
    <Dropdown.Toggle as={CustomToggle}>
      <MoreVertical size="15px" className="text-secondary" />
    </Dropdown.Toggle>
    <Dropdown.Menu align="end">
      {actions.map((action, index) => (
        <Dropdown.Item 
          key={index} 
          eventKey={index}
          onClick={action.onClick}
          >
          {action.icon} {action.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const ReusableTable = ({
  data,
  columns,
  actions = [],
  showActionColumn = true,
  tableName = 'Table'
}) => {
  return (
    <div className="table-responsive overflow-y-hidden">
      <Table hover className="text-nowrap table-centered">
        <thead className="table-light">
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
            {showActionColumn && actions.length > 0 && <th>Action</th>}
          </tr>
        </thead>
        <tbody> 
          {data?.length > 0 ? (
            data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns?.map((column, colIndex) => (
                  <td key={colIndex}>
                    {/* Render Cell Content Based on Column Type */}
                    {column.render
                      ? column.render(row[column.accessor], row)
                      : row[column.accessor]}
                  </td>
                ))}
                {showActionColumn && actions.length > 0 && (
                  <td>
                    <ActionMenu actions={actions} />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns?.length + (showActionColumn ? 1 : 0)}>
                No {tableName} Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ReusableTable;

import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Spinner, Alert, Badge, Accordion, Button } from 'react-bootstrap';
import { useFetchData } from 'hooks/useFetchData';
import { usePostData } from 'hooks/usePostData';
import { notifySuccess } from 'helper/utils';
import AileadButton from 'components/marketing/common/button/AileadButton';

const Cohort = () => {
    const { postData, loading, error, response } = usePostData('/cohort/save/settings');
    const { data: careers, refreshData, loading: loadingCareer } = useFetchData('/cohort/basic');
    const { data: currenciesData } = useFetchData('/course/currencies');
    const [enableManualMode, setEnableManualMode] = useState(false);
    const [careerPrice, setCareerPrice] = useState([{careerId: "", prices: []}]); // Array of objects for prices
    const [selectedCourses, setSelectedCourses] = useState([]); // Array to track courses per career
    const [selectedCareer, setSelectedCareer] = useState([]); // Array for multiple career selection

    useEffect(() => {
        if (response?.data && response?.status === 'Success') {
            refreshData();
            notifySuccess('Settings successfully updated!');
        }
    }, [response]);

    // Inside useEffect, set careerPrice when server data is available
    useEffect(() => {
        if (careers) {
            if(currenciesData) {
                const initialPrices = careers?.data?.data?.map(career => ({
                    careerId: career.id,
                    prices: career.prices.length > 0
                    ? career.prices.map(price => ({
                        id: price.id,
                        currency: price.currency, // Ensure you're using the 'id' for currency
                        amount: price.amount,
                    }))
                    : currenciesData?.data?.map(currency => ({
                        currency: currency.id,
                        amount: '', // Initialize with default value if no price data from the server
                    })) // If no prices from server, fallback to default values

                }));
                setCareerPrice(initialPrices); // Set career prices from the server
            }

            // set Selected courses initial values on server loaded
            const initialSelectedCourses = careers?.data?.data?.map(career => ({
                careerId: career.id,
                courses: career.courses
                    .filter(course => course.isExcluded) // Initially include only non-excluded courses
                    .map(course => course.id),
            }));
            setSelectedCourses(initialSelectedCourses);

            // set career initial value on server loaded
            const initialSelectedCareer = careers?.data?.data?.filter(career => career.isExcluded).map(career => career.id);
            setSelectedCareer(initialSelectedCareer);
        }
    }, [careers, currenciesData]);
    
    // Handle price changes for dynamic currencies
    const handlePriceChange = (careerId, amount, currencyId) => {
        setCareerPrice(prevPrices => {
            const newPrices = prevPrices.map(careerPrice => {
                if (careerPrice.careerId === careerId) {
                    const updatedPrices = careerPrice.prices.map(price => {
                        return price.currency === currencyId 
                            ? { ...price, amount: amount || '' }
                            : price;
                    });

                    return { ...careerPrice, prices: updatedPrices };
                }
                return careerPrice;
            });
            return newPrices;
        });
    };           

    // Handle career selection (multiple selection with highlighting)
    const handleCareerSelection = (careerId) => {
        setSelectedCareer(prevSelectedCareers => {
            if (prevSelectedCareers.includes(careerId)) {
                // Deselect career
                return prevSelectedCareers.filter(career => career !== careerId).filter(career => !career.isExcluded);
            } else {
                // Select career
                return [...prevSelectedCareers, careerId];
            }
        });
    };

    // Handle course selection per career
    const handleCourseSelection = (careerId, courseId) => {
        setSelectedCourses(prevSelectedCourses => {
            // Find the career selection in the state
            const careerSelection = prevSelectedCourses.find(c => c.careerId === careerId);
    
            if (careerSelection) {
                // Career exists, update its courses
                const isCourseSelected = careerSelection.courses.includes(courseId);
                const updatedCourses = isCourseSelected
                    ? careerSelection.courses.filter(id => id !== courseId)  // Remove course if it was selected
                    : [...careerSelection.courses, courseId];  // Add course if not selected
                
                return prevSelectedCourses.map(c => 
                    c.careerId === careerId ? { ...c, courses: updatedCourses } : c
                );
            } else {
                // Career doesn't exist, create new selection with this course
                return [...prevSelectedCourses, { careerId, courses: [courseId] }];
            }
        });
    };

    // Submit the settings
    const updateSettings = () => {
        const payload = {
            cohort_manual_mode: enableManualMode,
            prices: careerPrice,
            excludedCourses: selectedCourses,
            cohort_selected_career: selectedCareer,
        };
        postData(payload);
    };

    return (
        <Fragment>
            <Row className="justify-content-center">
                <Col xl={10} lg={12} md={12}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
                        <Breadcrumb.Item active>Cohort Setup</Breadcrumb.Item>
                    </Breadcrumb>

                    <Card className="mb-4">
                        <Card.Header>
                            <h5 className="mb-0">Cohort Settings</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Form.Group controlId="manualModeSwitch" className="mb-4">
                                    <Form.Check 
                                        type="switch"
                                        label="Enable Manual Mode"
                                        checked={enableManualMode}
                                        onChange={() => setEnableManualMode(!enableManualMode)}
                                    />
                                </Form.Group>

                                {loadingCareer ? (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        <p className="ms-3">Loading careers, please wait...</p>
                                    </div>
                                ) : (
                                    <>
                                        <Form.Group controlId="careerSelect" className="mb-4">
                                            <Form.Label>Select Career</Form.Label>
                                            <div className="d-flex flex-wrap gap-2">
                                            {careers?.data?.data?.map((career) => {
                                                // Check if career is excluded from server
                                                const isExcludedFromServer = career.isExcluded;

                                                // Check if career is selected by the user
                                                const isSelectedByUser = selectedCareer.includes(career.id);

                                                // Final selected state: User selection overrides exclusion, otherwise, default to server state
                                                const isFinalSelected = typeof isSelectedByUser === 'boolean'
                                                    ? isSelectedByUser
                                                    : !isExcludedFromServer;

                                                return (
                                                    <Button
                                                        key={career.id}
                                                        variant={isFinalSelected ? 'primary' : 'secondary'}
                                                        onClick={() => handleCareerSelection(career.id)}
                                                    >
                                                        {career.title}
                                                    </Button>
                                                );
                                            })}
                                            </div>
                                        </Form.Group>

                                        {selectedCareer?.length > 0 && (
                                            <Accordion defaultActiveKey="0">
                                                {selectedCareer?.map((careerId, index) => (
                                                    <Accordion.Item eventKey={index.toString()} key={careerId}>
                                                        <Accordion.Header>
                                                            {careers?.data?.data?.find(c => c.id === careerId)?.title}
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                {/* Naira Price Input */}
                                                                {currenciesData?.data?.map((currency) => (
                                                                    <Col md={6} key={currency.id}>
                                                                        <Form.Group className="mb-3">
                                                                            <Form.Label>Price ({currency.name})</Form.Label>
                                                                            <Form.Control
                                                                                type="number"
                                                                                placeholder={`Enter price in ${currency.symbol}`}
                                                                                value={
                                                                                    careerPrice?.find((item) => item.careerId === careerId)?.prices?.find(
                                                                                        (price) => price.currency === currency.id
                                                                                    )?.amount // If no price, set to ''
                                                                                }
                                                                                onChange={(e) =>
                                                                                    handlePriceChange(careerId, e.target.value, currency.id) // Allow changes even if empty
                                                                                }
                                                                            />
                                                                        </Form.Group>
                                                                    </Col>
                                                                ))}
                                                            </Row>

                                                            <Form.Group className="mb-3">
                                                                <Form.Label>Select Courses</Form.Label>
                                                                <div className="d-flex flex-wrap gap-2">
                                                                    {careers?.data?.data
                                                                        ?.find((career) => career.id === careerId)
                                                                        ?.courses?.map((course) => {
                                                                            // Check if the course is selected by the user
                                                                            const careerSelection = selectedCourses.find(c => c.careerId === careerId);
                                                                            const isSelectedByUser = careerSelection?.courses.includes(course.id);

                                                                            // Handle exclusion state from the server
                                                                            const isExcludedFromServer = course.isExcluded;

                                                                            // Final determination: user selection overrides the server exclusion, otherwise, default to server state
                                                                            const isFinalSelected = typeof isSelectedByUser === 'boolean'
                                                                                ? isSelectedByUser
                                                                                : !isExcludedFromServer;

                                                                            return (
                                                                                <Badge
                                                                                    key={course.id}
                                                                                    bg={isFinalSelected ? 'secondary' : 'primary'}
                                                                                    className="p-2"
                                                                                    style={{ cursor: 'pointer' }}
                                                                                    onClick={() => handleCourseSelection(careerId, course.id)}
                                                                                >
                                                                                    {course.title}
                                                                                    {/* Optionally show an indicator for excluded courses */}
                                                                                    {isExcludedFromServer && !isSelectedByUser && (
                                                                                        <span style={{ marginLeft: '8px', fontStyle: 'italic', fontSize: '12px', color: '#f00' }}>
                                                                                            (Excluded)
                                                                                        </span>
                                                                                    )}
                                                                                </Badge>
                                                                            );
                                                                        })}
                                                                </div>
                                                            </Form.Group>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        )}

                                        {error && <Alert variant="danger">{error}</Alert>}

                                        <div className="text-end mt-4">
                                            <AileadButton
                                                variant="primary"
                                                text="Save Settings"
                                                onClick={updateSettings}
                                                loading={loading}
                                                type="button"
                                            />
                                        </div>
                                    </>
                                )}
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
};

export default Cohort;

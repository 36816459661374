import React from 'react';
import ReusableTable from '../../common/table/ReusableTable';
import { CourseInitialColumn } from 'components/dashboard/business/all-courses/common/Components';
import { useNavigate } from 'react-router-dom';

const CourseTable = ({ courses }) => {

  const navigate = useNavigate();
  const columns = [
    { header: '#', accessor: '' },
    { header: 'Course', accessor: 'image', render: (value, row) => (<CourseInitialColumn course={row} />)},
    { header: 'Category', accessor: 'category', render: (value, row) => (<div> {row.category.map((item, i) => item.title).join(',')} </div>) },
    { header: 'Enrolled', accessor: 'studentsEnrolled' },
    { header: 'Royalty/Student', accessor: 'royalty' },
    { header: 'Date', accessor: 'publishedDate' },
    { header: 'Status', accessor: 'status' },
  ];

  const actions = [
    { label: 'View/Edit', icon: null, onClick: (row) => navigate(`/course/update/${row.slug}`) }
  ];

  return <ReusableTable data={courses?.data} columns={columns} actions={actions} tableName="Course" />;
};

export default CourseTable;

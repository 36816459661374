import React from 'react';
import ReusableTable from '../../common/table/ReusableTable';
import { useNavigate } from 'react-router-dom';

const QuizTable = ({ quizzes }) => {
  const navigate = useNavigate();
  
  const columns = [
    { header: '#', accessor: '' },
    { header: 'Quiz', accessor: 'customerName' },
    { header: 'Category', accessor: 'amount' },
    { header: 'Enrolled', accessor: 'date' },
    { header: 'Payout', accessor: 'payout' },
    { header: 'Date', accessor: 'date' },
    { header: 'Status', accessor: 'status' },
  ];

  const actions = [
    { label: 'View/Edit', icon: null, onClick: (row) => navigate(`/quiz/update/${row.id}`) }
  ];

  return <ReusableTable data={quizzes} columns={columns} actions={actions} tableName="Quiz" />;
};

export default QuizTable;
